import axios, { AxiosError, AxiosResponse } from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

const apiClient = axios.create({
    baseURL: API_URL,
    headers: {
        'sw-access-key': `${process.env.VUE_APP_SW_ACCESS_KEY}`,
    }
});

const setAuthorizationHeader = (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['sw-context-token'] = `${token}`;
    }
    return config;
};

apiClient.interceptors.request.use(setAuthorizationHeader);

const post = async (endpoint: string, payload: any = {}): Promise<any> => {
    try {
        return await apiClient.post(endpoint, payload);
    } catch (error: any | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const get = async (endpoint: string): Promise<any> => {
    try {
        return await apiClient.get(endpoint);
    } catch (error: any | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

export function useApi() {
    return {
        apiClient,
        post,
        get,
    };
}
