import axios, { AxiosError, AxiosResponse } from 'axios';
import { reactive } from 'vue';
import { useApi } from './api';

interface AuthState {
    loading: boolean;
    user: any | null;
    error: any | null;
}

export const userState = reactive({
    user: null
});

export function useAuth() {
    const state: AuthState = reactive({
        loading: false,
        user: null,
        error: null,
    });

    const { get, post } = useApi();

    const login = async (username: string, password: string): Promise<void> => {
        state.error = null;
        try {
            const response: AxiosResponse = await post('/account/login', {
                email: username,
                password: password,
            });
            localStorage.setItem('token', response.data.contextToken);
        } catch (error: any | AxiosError) {
            if (axios.isAxiosError(error)) {
                state.error = error;
                return;
            }

            state.error = error;
        }
    };

    const getUserInformation = async (): Promise<void> => {
        try {
            const response: AxiosResponse = await get('/account/customer', );
            userState.user = response.data;
        } catch (error: any | AxiosError) {
            if (axios.isAxiosError(error)) {
                state.error = error.response?.data;
                return;
            }

            state.error = 'Unknown error';
        }
    };

    const logout = async (): Promise<void> => {
        try {
            await post('/account/logout');
            localStorage.removeItem('token');
            userState.user = null;
        } catch (error: any | AxiosError) {
            console.log(error);
            state.error = error;
        }
    };

    return {
        state: state as Readonly<AuthState>,
        userState: userState,
        getUserInformation,
        login,
        logout,
    };
}