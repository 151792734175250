import { reactive } from 'vue';
import { useApi } from './api';

interface WorkplaceState {
    loading: boolean;
    data: any | null;
    error: any | null;
}

export const workplaceState = reactive({
    bookedWorkplaces: null
});

export function useWorkplace() {
    const state: WorkplaceState = reactive({
        loading: false,
        data: null,
        error: null,
    });

    const { post } = useApi();

    const fetchAvailableWorkplaces = async (date: string): Promise<void> => {
        try {
            state.loading = true;
            const response = await post('hs/tf-available-workplace', {
                date: date.split('T')[0]
            });
            state.data = response.data;
        } catch (error: any) {
            state.error = error;
        } finally {
            state.loading = false;
        }
    };

    const fetchBookedWorkplaces = async (): Promise<void> => {
        try {
            state.loading = true;
            const response = await post('hs/tf-booking');
            state.data = response.data;
        } catch (error: any) {
            state.error = error;
        } finally {
            state.loading = false;
        }
    };

    const fetchLatestBookedWorkplaces = async (): Promise<void> => {
        try {
            state.loading = true;
            const response = await post('hs/tf-booking', { limit: 5 });
            workplaceState.bookedWorkplaces = response.data.elements;
        } catch (error: any) {
            state.error = error;
        } finally {
            state.loading = false;
        }
    };

    const bookWorkplace = async (workplaceId: string, bookingDate: string): Promise<void> => {
        try {
            state.error = null;
            state.loading = true;
            const response = await post('hs/tf-book-workplace', {
                workplaceId: workplaceId,
                bookingDate: bookingDate.split('T')[0]
            });

            if (response.errors && response.errors.length) {
                state.error = response.errors[0];
                return;
            }

            state.data = response.data;
        } catch (error: any) {
            state.error = error;
        } finally {
            state.loading = false;
        }
    }

    const cancelBooking = async (bookingId: string): Promise<void> => {
        try {
            state.loading = true;
            const response = await post('hs/tf-cancel-booking', {
                id: bookingId
            });

            if (response.errors.length) {
                state.error = response.errors[0];
                return;
            }

            state.data = response.data;
        } catch (error: any) {
            state.error = error;
        } finally {
            state.loading = false;
        }
    };

    const resetWorkplaceState = (): void => {
        state.data = null;
        state.error = null;
        workplaceState.bookedWorkplaces = null;
    };

    return {
        state: state as Readonly<WorkplaceState>,
        workplaceState: workplaceState,
        fetchAvailableWorkplaces,
        fetchBookedWorkplaces,
        fetchLatestBookedWorkplaces,
        bookWorkplace,
        cancelBooking,
        resetWorkplaceState
    };
}