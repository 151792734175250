import { useApi} from '@/composables/api';
import { AxiosError } from 'axios';

const { apiClient } = useApi();

export default function checkAuth(to: any, from: any, next: any, isAuthenticated: boolean) {
    apiClient.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
            if (error.response?.status === 403) {
                sessionStorage.removeItem('token');

                console.log('You are not authorized to access this resource.');
            }

            return Promise.reject(error);
        });

    if (!isAuthenticated && to.meta.requiresAuth) {
        return next({ name: "Login" });
    }

    return next();
}